









































































































































































































































































































































































































































































































import Vue from 'vue';
import formSwitchVue from '@/components/form/form-switch.vue';
import CountriesList from '@/components/form/countries-list.vue';
import { CASE_STATUS } from '@/constant/constants';
import { getNextRouteNameByKeyToCustomerGeneralHelper, getNextRouteNameByKeyToPricePageHelper, checkData } from '@/helper/functions';
import priceCalculator from '@/service/price-calculator';
import errorMessage from '@/components/form/error-message.vue';

const companyFields = {
  typeOfCompany: '',
  name1: '',
  name2: '',
  name3: '',
  mitarbeiterzahl: '',
  strasse: '',
  hausnummer: '',
  plz: '',
  ort: '',
  land: '',
  telefon: '',
  email: '',
  mobilnummer: '',
  ansprechpartner: '',
  gesamtumsatz: 0,
};

export default Vue.extend({
  name: 'customer-data',
  components: {
    'form-switch': formSwitchVue,
    'countries-list': CountriesList,
    errorMessage,
  },
  watch: {
    otherCompanies(value: boolean) {
      this.isAlertActive = value;
      return value;
    },
  },
  props: ['customerData', 'caseStatus', 'insuranceKey', 'isAPIObserver', 'sourceSystem', 'insuranceFormsApiData'],
  data() {
    return {
      companyOrPrivate: 'Firma',
      companyType: '',
      externalNumber: '',
      name1: '',
      name2: '',
      name3: '',
      noOfEmployees: '',
      street: '',
      houseNo: '',
      postCode: '',
      place: '',
      country: 'Deutschland',
      phoneNo: '',
      email: '',
      emailCheck: '',
      emailCheckShow: true,
      mobileNo: '',
      contactPerson: '',
      otherCompanies: false,
      otherCompaniesData: [
        {
          ...companyFields,
        },
      ],
      isAlertActive: false,
      ...this.customerData,
    };
  },
  computed: {
    customerApiData(): any {
      return this.insuranceFormsApiData?.customerData?.user || [];
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_SEND;
      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
    pricePageUrl(): string {
      return getNextRouteNameByKeyToPricePageHelper(this.insuranceKey);
    },
    userIsAzam(): boolean {
      return (this.sourceSystem.toUpperCase() === 'AZAM' || this.sourceSystem.toUpperCase() === 'AZMS');
    },
  },
  mounted() {
    if (this.$attrs.formData.customerData.user) {
      this.setValues(this.$attrs.formData.customerData.user);
    }
    // console.log(this.customerData);
    if (this.isDisableAll) {
      this.disableAllInputs();
    }
  },
  methods: {
    existsInApiData(key: string) {
      return false;
      // for ET-500 remove comment
      // return checkData(this.customerApiData[key]);
    },
    setValues(formData: any) {
      this.email = formData.email;
      this.emailCheck = formData.email ?? '';
      this.emailCheckShow = false;
      this.mobileNo = formData.mobileNo;
      this.companyType = formData.companyType;
      this.name1 = formData.name1;
      this.name2 = formData.name2;
      this.name3 = formData.name3;
      this.phoneNo = formData.phoneNo;
      this.place = formData.place;
      this.postCode = formData.postCode;
      this.street = formData.street;
      this.externalNumber = formData.externalNumber;
    },
    goToBackUrl() {
      this.$router.push({
        name: this.pricePageUrl,
      }).catch((err: any) => {
        throw new Error(`Problem handling something: ${err}.`);
      });
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    addAnother() {
      this.otherCompaniesData.push(companyFields);
    },
    getCityByZipCode(event: any, key = -1) {
      if (key === -1) {
        priceCalculator.checkZipCode(this.postCode).then((response) => {
          this.place = response.data.postalCode.city;
        });
      } else {
        priceCalculator.checkZipCode(this.otherCompaniesData[key].plz).then((response) => {
          this.otherCompaniesData[key].ort = response.data.postalCode.city;
        });
      }
    },
    scrollToFirstErrorElement() {
      const el = this.$el.getElementsByClassName('priceError')[0];
      if (el) {
        el.scrollIntoView();
      }
    },
    onNextClick() {
      if (this.emailCheck !== this.email && !this.userIsAzam) {
        setTimeout(() => this.scrollToFirstErrorElement(), 500);
        return;
      }
      let data: any = {
        companyOrPrivate: this.companyOrPrivate,
        companyType: this.companyType,
        name1: this.name1,
        name2: this.name2,
        name3: this.name3,
        noOfEmployees: this.noOfEmployees,
        street: this.street,
        houseNo: this.houseNo,
        postCode: this.postCode,
        place: this.place,
        country: this.country,
        phoneNo: this.phoneNo,
        email: this.email,
        mobileNo: this.mobileNo,
        contactPerson: this.contactPerson,
        otherCompanies: this.otherCompanies,
        externalNumber: this.externalNumber,
      };

      if (this.otherCompanies) {
        data = {
          ...data,
          otherCompaniesData: [...this.otherCompaniesData],
        };
      }

      this.$emit('customer-data-changed', data);
      const routeName = getNextRouteNameByKeyToCustomerGeneralHelper(this.insuranceKey);
      this.$router.push({
        name: routeName,
        params: {
          id: this.$route.params.id,
        },
      });
    },
  },
});
